<template>
    <div>
        <div class="content" v-for="(item,i) in list" :key="i">
            <img :src="item.thumb" v-if="item.thumb" alt="" class="left">
            <div class="right">
                <div class="cursor">
                    <div style="font-weight:bold;font-size:24px;">{{item.title}} </div>
                    <div style="color:#666;font-size:14px;margin-top:10px;">{{item.desc}}</div>
                </div>
            </div>
        </div>
        <Page v-if="list.length!=0" :pageSize="page_size" :total="total" :current="page" @change="pageChange"/>
        <div class="t-center" style="padding:40px 0px;" v-else>
            <img src="shexie/img/nodata1.png" alt="">
            <div>暂无数据</div>
        </div>
    </div>
</template>

<script>
// import {articles} from '@/api/survey.js'
import {articles} from '@/api/news.js'
export default {
    data() {
        return {
            page_size:10,   //每页条数
            total:0,        //总条数
            page:1,        //当前页
            list:[]
        };
    },
    created() {
        this.getList()
    },
    mounted() {

    },
    methods: {
        getList(){
            articles({cate_id:this.$route.query.id,page:this.page,page_size:this.page_size,}).then(res=>{
                if(res.code==200){
                    this.list = res.data.data
                    this.total = res.data.total
                }
            })
        },
        //分页
        pageChange(val){
            this.page  = val;
            this.getList()
        },
    }
};
</script>

<style scoped lang="scss">
    .content{
        position: relative;
        margin-top: 30px;
        .left{
            width: 200px;
            height: 240px;
            position: absolute;
            top:20px;
        }
        .right{
            height:260px;
            width: 630px;
            margin-left: 120px;
            padding: 20px 20px 0px 110px;
            background: #fff;
            border: 1px solid #E6E6E6;
            box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.1);
        }
    }
</style>
